import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { BreadCrumbs } from '../molecules/BreadCrumbs'
import { NavigateMain } from '../organisms/NavigateMain'
import { useTrackLaunchNavigateViewEffect } from '../components/amplitude/trackLaunchNavigateView'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
`

const TopDecoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 98.1%),
    #e3f5f5;
  transform: rotate(-180deg);
  width: 100%;
  height: 302px;
`

const Navigate = () => {
  useTrackLaunchNavigateViewEffect()
  return (
    <App>
      <SEO
        description="Вы можете подобрать психолога самостоятельно с помощью нашего алгоритма или оставить заявку на помощь от специалистов YouTalk"
        title="Заявка на поиск специалиста | YouTalk"
      />
      <TopDecoration />
      <Header />
      <BreadCrumbs />
      <Page>
        <NavigateMain />
        <Footer />
      </Page>
    </App>
  )
}

export default Navigate
