import React from 'react'
import styled from 'styled-components'
import { Grid } from '../atoms/Grid'
import { NavigationCards } from '../molecules/NavigationCards/NavigationCards'
import { Text } from '../atoms/Text'
import { Title } from '../atoms/Title'
import { size } from '../constants'

const PageContent = styled.div`
  width: 100%;
  top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0 auto;
  width: 66%;
  margin-bottom: 114px;

  & ${Text.Medium} {
    white-space: pre-line;

    @media (max-width: ${size.sm}) {
      white-space: normal;
    }
  }

  @media (max-width: ${size.lg}) {
    width: 80%;
    margin-bottom: 88px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    margin-bottom: 38px;
  }

  @media (max-width: ${size.sm}) {
    margin-top: 40px;
    margin-bottom: 48px;
  }

  @media (max-width: ${size.xs}) {
    margin-top: 56px;
  }

  & ${Title.H1} {
    margin-bottom: 80px;
    white-space: nowrap;

    @media (max-width: ${size.md}) {
      margin-bottom: 64px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 56px;
    }

    @media (max-width: ${size.xxs}) {
      margin-bottom: 40px;
    }
  }
`

export const NavigateMain = styled(({ className }) => (
  <div className={className}>
    <Grid>
      <PageContent>
        <Title.H1>Подбор специалиста</Title.H1>
        <NavigationCards />
      </PageContent>
    </Grid>
    <div type="redLg" />
    <div type="yellowLg" />
    <div type="redMd" />
    <div type="yellowMd" />
  </div>
))`
  width: 100%;
  position: relative;

  & div[type='redLg'],
  & div[type='yellowLg'],
  & div[type='redMd'],
  & div[type='yellowMd'] {
    display: none;
  }

  @media (min-width: 1440px) {
    & div[type='redLg'] {
      left: 0;
      bottom: 0;
      background: url(/img/navigate/red-blob-lg.svg) no-repeat;
      background-size: cover;
      width: 63px;
      height: 301px;
    }

    & div[type='yellowLg'] {
      right: 0;
      top: 34px;
      background: url(/img/navigate/yellow-blob-lg.svg) no-repeat;
      background-size: cover;
      width: 90px;
      height: 562px;
    }

    & div[type='redLg'],
    & div[type='yellowLg'] {
      position: absolute;
      display: block;
      pointer-events: none;
    }
  }

  @media (min-width: 1024px) and (max-width: ${size.lg}) {
    & div[type='redMd'] {
      right: 0;
      top: 229px;
      background: url(/img/navigate/red-blob-md.svg) no-repeat;
      background-size: cover;
      width: 95px;
      height: 264px;
    }

    & div[type='yellowMd'] {
      left: 0;
      bottom: 0;
      background: url(/img/navigate/yellow-blob-md.svg) no-repeat;
      background-size: cover;
      width: 90px;
      height: 562px;
    }

    & div[type='redMd'],
    & div[type='yellowMd'] {
      position: absolute;
      display: block;
      pointer-events: none;
    }
  }
`
