import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { ReactComponent as CatalogSVG } from './img/catalog.svg'
import { ReactComponent as FastSVG } from './img/fast.svg'
import { Event as GAEvent, trackVisitForm } from '../../components/GA'
import { LoggedInPopup } from '../LoggedInPopup'
import { Text } from '../../atoms/Text'
import { navigate } from 'gatsby'
import { size } from '../../constants'
import { trackCatalogOpen } from '../../components/amplitude/trackCatalogOpen'
import { useLoggedUsername } from '../../components/Header/useLoggedUsername'

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HelpCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  justify-content: space-between;
  box-shadow: 0px 30px 50px rgba(3, 178, 165, 0.1);
  border-radius: 24px;
  padding: 24px;
  height: 316px;

  @media (max-width: ${size.md}) {
    padding: 24px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    height: auto;

    & ${TextContent} {
      margin-bottom: 16px;
    }

    & ${Text.Medium} {
      font-size: 14px;
      line-height: 20px;

      & br {
        display: none;
      }
    }
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

const CardTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: 16px;
  font-weight: 700;
`

const PrimaryButton = styled(Button.NewPrimary)`
  width: 100%;
`

const ImageContainer = styled.div`
  height: 80px;
`

const TextContentOpenCatalog = () => (
  <TextContent>
    <CardTitle>Быстрая анкета</CardTitle>
    <Text.Medium>
      Расскажите, кому и с чем нужна
      <br /> помощь, и получите список
      <br /> подходящих психологов на выбор
    </Text.Medium>
  </TextContent>
)

const TextContentWithSpecialist = () => (
  <TextContent>
    <CardTitle>Каталог психологов</CardTitle>
    <Text.Medium>
      Выберите своего психолога из более чем 300 специалистов самостоятельно
    </Text.Medium>
  </TextContent>
)

// eslint-disable-next-line max-lines-per-function, arrow-body-style
export const NavigationCards = styled(({ className }) => {
  const [showLoggedInPopup, setShowLoggedInPopup] = useState(false)
  const [loggedUserName] = useLoggedUsername()

  const handleClick = (route, event) => {
    if (loggedUserName) {
      setShowLoggedInPopup(true)
    } else {
      GAEvent.SelectionOnNavigatePage(event)
      trackCatalogOpen({
        source: 'navigate'
      })
      if (route === '/catalog/') {
        trackVisitForm('navigateCoordinator')
      }
      navigate(route)
    }
  }

  return (
    <div className={className}>
      <HelpCard>
        <ImageContainer>
          <FastSVG />
        </ImageContainer>
        <TextContentOpenCatalog />
        <PrimaryButton
          onClick={() => handleClick('/wizard/', 'navigateIndependent')}
        >
          Заполнить сейчас
        </PrimaryButton>
      </HelpCard>
      <HelpCard>
        <ImageContainer>
          <CatalogSVG />
        </ImageContainer>
        <TextContentWithSpecialist />
        <Button.Outline
          onClick={() => handleClick('/catalog/', 'navigateCoordinator')}
        >
          Открыть каталог
        </Button.Outline>
      </HelpCard>
      {showLoggedInPopup && (
        <LoggedInPopup hide={() => setShowLoggedInPopup(false)} />
      )}
    </div>
  )
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: ${size.sm}) {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
  }
`
