import { pricesMapForAmplitude } from '../../atoms/mappers/wizard/pricesForAmplitudeMap'
import { useEffect } from 'react'

export const getWordBool = (ask) => (ask ? 'yes' : 'no')

export const useTrackEffect = (cb, properties) =>
  useEffect(() => {
    cb(properties)
  }, [])

export const getPricesStringFromArray = (prices) =>
  prices.map((price) => pricesMapForAmplitude[price]).join(', ')
