import React from 'react'
import styled, { css } from 'styled-components'
import {
  Button,
  Illustrations,
  Popup,
  Text,
  size
} from '../../youtalk-storybook/src/ui'
import { selectDeviceSizeIsMobile } from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }

  ${Button.NewPrimary} {
    width: 127px;

    @media (max-width: ${size.sm}) {
      width: 50%;
    }
  }

  ${Button.Grey} {
    width: 127px;

    @media (max-width: ${size.sm}) {
      width: 50%;
    }
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
`

const LoggedInPopupContent = styled(({ className, hide, isMobile }) => (
  <div className={className}>
    <Illustrations.IllustrationProblem size={isMobile ? 'small' : 'medium'} />
    <TextBlock>
      <Text.Large bold>Хотите поменять психолога?</Text.Large>
      <Text.Average>
        Расскажите специалисту о причинах, которые мешают эффективно работать с
        текущим психологом. Нам это важно.
      </Text.Average>
    </TextBlock>
    <ButtonRow>
      <Button.NewPrimary href="https://t.me/YouTalk_clients_bot" type="link">
        Да
      </Button.NewPrimary>
      <Button.Grey onClick={hide}>Нет</Button.Grey>
    </ButtonRow>
  </div>
))`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
`

export const LoggedInPopup = styled(({ className, hide }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)

  return (
    <Popup
      showCloseButton
      bodyClassName={className}
      css={css`
        height: var(--height);
      `}
      hide={hide}
      isMobile={isMobile}
    >
      <LoggedInPopupContent hide={hide} isMobile={isMobile} />
    </Popup>
  )
})`
  max-width: 504px;

  @media (max-width: ${size.sm}) {
    max-width: 100%;
  }
`
